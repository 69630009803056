<template>
  <div class="text-charcoal-grey bg-pure-white">
    <ViewHeadSection :description-text="$t('impressum.top_right_title_text')">
      <div class="w-full flex-no-shrink">
        <p class="font-sans-bold text-xl text-faded-red">{{ $t('impressum.top_left_title_text') }}</p>
        <p class="font-sans text-lg">{{ $t('impressum.top_right_title_text') }}</p>
        <p class="font-sans text-lg">Fabian Kölliker</p>
      </div>
    </ViewHeadSection>
    <div class="bg-pure-white mb-50">
      <div class="pl-39 pt-32">
          <div class="pb-15" v-if="$i18n.locale === 'fr'">
            <h3>JURIDIQUE</h3>
            <p>Tous les textes et liens ont été soigneusement vérifiés et sont mis à jour en permanence. Nous nous
efforçons de fournir des informations exactes et complètes sur ce site Web, mais nous déclinons toute
responsabilité, garantie ou obligation, quelle qu’elle soit, quant à l’exactitude, l’exhaustivité ou l’actualité
des informations fournies par ce site Web. Nous nous réservons le droit de modifier les informations
contenues sur ce site à tout moment et sans préavis et ne nous engageons pas à mettre à jour les
informations qu’il contient. Tous les liens vers des fournisseurs externes ont été vérifiés au moment de
leur intégration, mais nous ne sommes pas responsables du contenu et de la disponibilité des sites Web
accessibles par des liens hypertextes. Pour les contenus illégaux, incorrects ou incomplets et en
particulier pour les dommages qui résultent des contenus des pages liées, seul l’exploitant de la page
correspondante est responsable. Cela peut comprendre un dommage direct, indirect ou financier ou tout
autre dommage pouvant résulter d’une perte de données, d’une perte d’utilisation ou d’autres raisons de
quelque nature que ce soit.</p>
            <h3 class="mt-15">DROIT D’AUTEUR</h3>
            <p>Les droits d’auteur des contenus publiés ici appartiennent à SWISS KRONO AG. Les contenus ne
peuvent être utilisés, même sous forme d’extraits, que sous l’indication de la source « SWISS KRONO
AG ».</p>
          </div>
          <p
              v-html="informations[$i18n.locale]"
              class="text-style-7 whitespace-pre-line leading-22"
          >
          </p>
      </div>
    </div>
    <p class="text-lg font-sans-bold pl-39">COPYRIGHT</p>
    <div
        class="flex text-base mt-17 pl-39"
        @click="openCopyright('https://www.swisskrono.com/global-en/imprint/#/')"
    >
      <p>{{ $t('impressum.copyright_open_link_text') }}</p>
      <IconArrow
          class="text-faded-red cursor-pointer ml-10"
          direction="right"
      />
    </div>
    <div class="bg-pure-white h-80"></div>
  </div>
</template>


<script>
import IconArrow from '@/components/icon/IconArrow';
import ViewHeadSection from '@/components/view/ViewHeadSection';

const email = '<a class="text-faded-red no-underline" href="mailto:info@swisskrono.com">' +
  'fabian.koelliker@swisskrono.com</a>';

export default {
  components: {
    IconArrow,
    ViewHeadSection,
  },
  data() {
    return {
      informations: {
        de: `SWISS KRONO Tec AG
            Museggstrasse 14
            6004 Luzern
            Schweiz
            UID-Nummer: CHE-101.028970

            Telefon: +41 (0)41 419 03 20
            Telefax: +41 (0)41 419 03 25

            E-Mail: ${email}

            Angaben gemäß § 5 TMG`,
        en: `SWISS KRONO Tec AG
            Museggstrasse 14
            6004 Luzern
            Switzerland
            UID-Number: CHE-101.028970

            Tel.: +41 (0)41 419 03 20
            Fax: +41 (0)41 419 03 25

            E-mail: ${email}

            This information complies with Art. 5 of the German Teleservices Act (TMG)`,
        fr: `SWISS KRONO SAS
            Address: Rte de Cerdon, 
            45600 Sully-sur-Loire, France
            Phone: +33 2 38 37 37 37

            E-mail: ${email}`,
      },
    };
  },
  methods: {
    openCopyright(link) {
      window.open(link);
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
